import React from "react";
import { connect } from "react-redux";
import { successAuth } from "../actions/auth.js";
import { getRequest, postRequest } from "../actions/api.js";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Nav,
} from "reactstrap";
import Container from "reactstrap/es/Container";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";
import logo from "../theme/logo.png";

import signin from "../icons/sign-in-alt.png";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = true;

    this.state = {
      username: "",
      password: "",
      isFetching: false,
      message: "",
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("token");

    if (token) {
      this.setState({
        isFetching: true,
      });

      this.props
        .checkLoginStatus()
        .then((response) => {
          this.props.login(response.data.username, token);
        })
        .catch(() => {
          this.setState({
            message: "A munkamenet lejárt",
          });
        })
        .then(() => {
          if (this._isMounted) {
            this.setState({
              isFetching: false,
            });
          }
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    });
  };

  tryLogin = (e) => {
    e.preventDefault();
    this.setState({
      isFetching: true,
    });

    this.props
      .tryLogin(this.state.username, this.state.password)
      .then((response) => {
        this.props.login(response.data.user.username, response.data.token);
        localStorage.setItem("token", response.data.token);
      })
      .catch(() => {
        this.setState({
          message: "Hibás felhasználónév vagy jelszó",
        });
      })
      .then(() => {
        if (this._isMounted) {
          this.setState({
            isFetching: false,
          });
        }
      });
  };

  render() {
    return (
      <div>
        <div className=" container col-12 ">
          <div className="App-head row justify-content-md-center">
            <div className="offset-1 col-10">
              <Navbar light expand="md" id="mobile-navbar">
                <Col className="col-12 p-0">
                  <Nav className="justify-content-center">
                    <NavbarBrand>
                      <img height={150} src={logo} alt="Kisokos" />
                    </NavbarBrand>
                  </Nav>
                </Col>
              </Navbar>
              <Navbar light expand="md" id="desktop-navbar">
                <NavbarBrand>
                  <img src={logo} alt="Kisokos" height={150} />
                </NavbarBrand>
              </Navbar>
            </div>
          </div>
        </div>
        {this.state.isFetching ? (
          <p>Betöltés...</p>
        ) : (
          <Container className="col-12 col-md-3 offset-md-4 align-bottom Login-Box">
            <Form onSubmit={this.tryLogin}>
              <FormGroup>
                <Row className="display-flex justify-content-center mt-4 mt-md-0">
                  <Col className="col-12 col-md-4 App-label text-center text-md-left">
                    <Label htmlFor="username">Felhasználónév</Label>
                  </Col>
                  <Col className="col-8">
                    <Input
                      className="App-input"
                      onChange={this.handleChange}
                      value={this.state.username}
                      type="username"
                      name="username"
                      id="username"
                      placeholder="Pl: Kati"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className="display-flex justify-content-center mt-4 mt-md-0">
                  <Col className="col-12 col-md-4 App-label text-center text-md-left">
                    <Label htmlFor="password">Jelszó</Label>
                  </Col>
                  <Col className="col-8">
                    <Input
                      onChange={this.handleChange}
                      value={this.state.password}
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Pl: 123456"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <Row className="display-flex justify-content-center mt-4 mt-md-0">
                <Col>
                  <Button
                    className="float-md-right"
                    color="primary"
                    type="submit"
                    disabled={this.props.isFetching}
                  >
                    Bejelentkezés{" "}
                    <img className="App-img-sm" src={signin} alt={""} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkLoginStatus: () => {
    return dispatch(getRequest("/kisokostest/currentuser/"));
  },
  tryLogin: (username, password) => {
    return dispatch(
      postRequest(
        "/api-auth/",
        {
          username: username,
          password: password,
        },
        false,
        "Sikeres belépés",
        "Hibás felhasználónév vagy jelszó"
      )
    );
  },
  login: (name, token) => {
    dispatch(successAuth(name, token));
  },
});

export default connect(null, mapDispatchToProps)(Login);
