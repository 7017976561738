import axios from 'axios';
import {setAlert, toggleAlert} from "./apiresponse";

const API_BASE_URL = "https://mesterek-api.kisokos.hu";
// Default path after API_BASE_URL
// Structure: API_BASE_URL + API_ROOT_PATH + relative path, for example: http://localhost:8000/kisokostest/application/statistics
export const API_ROOT_PATH = '/kisokostest';

const getAuthHeaders = (state, auth) => {
  if (!auth)
    return {
      headers: {}
    };

  const token = state.auth.token ? state.auth.token : localStorage.getItem('token');

  return {
    headers: {
      Authorization: `JWT ${token}`
    }};
};

export const postRequest = (relativeURL, data, auth = true, OkMessage = 'Sikeres mentés', WrongMessage = 'Sikertelen mentés(Ellenőrizd az adatokat)') => {
  //console.log('api.js postRequest', relativeURL, data);
  return (dispatch, getState) => {
    const state = getState();
    //console.log('state = getState()', state);
    return axios.post(API_BASE_URL + relativeURL, data, getAuthHeaders(state, auth)).then(function(response) {
      //console.log('axios.post.then', response);
      dispatch(setAlert(OkMessage,"success"));
      window.setTimeout(()=>{dispatch(toggleAlert())},2000);
      return response
    }).catch( function (error) {
      //console.log('axios.post.then ERROR', error);
      dispatch(setAlert(WrongMessage,"danger"));
      window.setTimeout(()=>{dispatch(toggleAlert())},2000);
      return error
    });
  };
};

export const putRequest = (relativeURL, data, auth = true) => {
  return (dispatch, getState) => {
    const state = getState();

    return axios.put(API_BASE_URL + relativeURL, data, getAuthHeaders(state, auth)).then(function(response){
      dispatch(setAlert("Sikeres mentés","success"));
      window.setTimeout(()=>{dispatch(toggleAlert())},2000);
      return response
    }).catch( function (error) {
      dispatch(setAlert("Sikertelen mentés","danger"));
      window.setTimeout(()=>{dispatch(toggleAlert())},2000);
      return error
    });
  };
};

export const deleteRequest = (relativeURL, data, auth = true) => {
  return (dispatch, getState) => {
    const state = getState();

    return axios.delete(API_BASE_URL + relativeURL, getAuthHeaders(state, auth)).then(function(response){
      dispatch(setAlert("Sikeres törlés","success"));
      window.setTimeout(()=>{dispatch(toggleAlert())},2000);
      return response
    }).catch( function (error) {
      dispatch(setAlert("Sikertelen törlés","danger"));
      window.setTimeout(()=>{dispatch(toggleAlert())},2000);
      return error
    });
  };
};

export const getRequest = (relativeURL, auth = true) => {
  return (dispatch, getState) => {
    const state = getState();

    return axios.get(API_BASE_URL + relativeURL, getAuthHeaders(state, auth)).catch( function (error) {
        if(error.response.status === 403){
          dispatch(setAlert("Jogosultsági hiba","danger"));
        }
        else{
          dispatch(setAlert("Hálozati hiba","danger"));
        }

        window.setTimeout(()=>{dispatch(toggleAlert())},2000);
        return error
      });
  };
};

export const getRequestBlob = (relativeURL, auth = true) => {
  return (dispatch, getState) => {
    const state = getState();

    return axios.get(API_BASE_URL + relativeURL, { responseType: 'blob' }, getAuthHeaders(state, auth)).catch( function (error) {
        if(error.response.status === 403){
          dispatch(setAlert("Jogosultsági hiba","danger"));
        }
        else{
          dispatch(setAlert("Hálozati hiba","danger"));
        }

        window.setTimeout(()=>{dispatch(toggleAlert())},2000);
        return error
      });
  };
};
